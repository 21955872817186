export default [
  {
    path: '/systemManagement',
    name: 'systemManagement',
    component: () => import(/* webpackChunkName: "systemManagement" */ './index'),
    meta: {
      type: 'systemManagement'
    },
    redirect: '/systemManagement/serviceManagement',
    children: [
      {
        path: 'serviceManagement',
        name: 'serviceManagement',
        component: () => import(/* webpackChunkName: "systemManagement" */ './serviceManagement')
      },
      {
        path: 'serviceAccountManagement',
        name: 'serviceAccountManagement',
        component: () =>
          import(/* webpackChunkName: "systemManagement" */ './serviceAccountManagement')
      },
      {
        path: 'serviceRoleManagement',
        name: 'serviceRoleManagement',
        component: () =>
          import(/* webpackChunkName: "systemManagement" */ './serviceRoleManagement')
      },
      {
        path: 'userManagement',
        name: 'userManagement',
        component: () => import(/* webpackChunkName: "systemManagement" */ './userManagement')
      },
      {
        path: 'roleManagement',
        name: 'roleManagement',
        component: () => import(/* webpackChunkName: "systemManagement" */ './roleManagement')
      },
      {
        path: 'sourceManagement',
        name: 'sourceManagement',
        component: () => import(/* webpackChunkName: "systemManagement" */ './sourceManagement')
      },
      {
        path: 'operationLog',
        name: 'operationLog',
        component: () => import(/* webpackChunkName: "systemManagement" */ './operationLog')
      }
    ]
  }
]
